@tailwind base;
@tailwind components;

@font-face {
  font-family: Gotham;
  font-weight: 700;
  src: url(./resources/fonts/GothamRnd-Bold.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 200;
  src: url(./resources/fonts/GothamRnd-Book.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 300;
  src: url(./resources/fonts/GothamRnd-Light.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 400;
  src: url(./resources/fonts/GothamRnd-Medium.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 700;
  font-style: italic;
  src: url(./resources/fonts/GothamRnd-BoldIta.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 200;
  font-style: italic;
  src: url(./resources/fonts/GothamRnd-BookIta.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 300;
  font-style: italic;
  src: url(./resources/fonts/GothamRnd-LightIta.otf);
}
@font-face {
  font-family: Gotham;
  font-weight: 400;
  font-style: italic;
  src: url(./resources/fonts/GothamRnd-MedIta.otf);
}

.inp-border-primary {
  @apply border-gray-300 focus:border-primary-light focus:ring-primary-light;
}

.inp-border-red {
  @apply border-red-300 focus:border-red-500 focus:ring-red-500;
}

.inp-base {
  @apply focus:outline-none block w-full appearance-none rounded-xl border border-gray-300 px-4 py-3 font-light placeholder-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-lg;
}

.list-inside ul,
.list-inside ol {
  list-style-position: inside;
}

#payment-form {
  @apply w-full;
}

.kr-embedded {
  @apply mx-auto;
}

.kr-payment-button {
  @apply rounded-xl bg-primary !important;
}

.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-installment-number,
.kr-first-installment-delay {
  @apply inp-base h-10 p-1 !important;
}

.kr-embedded[krvue] .kr-pan,
.kr-embedded[krvue] .kr-expiry,
.kr-embedded[krvue] .kr-security-code {
  @apply p-1;
}

.grades_chart text {
  @apply text-base !important;
}

.pan-container {
  @apply pr-14;
}

.group:hover .img-white {
  filter: brightness(0) invert(1);
}

.fc-toolbar-chunk > div:not(.fc-button-group) {
  @apply flex items-center gap-2;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  @apply flex h-8 w-8 items-center justify-center rounded-3xl border-transparent bg-light-blue p-0 !important;
}

.fc .fc-prev-button .fc-icon,
.fc .fc-next-button .fc-icon {
  font-size: 1.2em;
}

.fc-toolbar-title {
  font-weight: 700;
  text-transform: uppercase;
}

.fc-button-group .fc-button-primary {
  @apply border-primary bg-transparent text-primary;
}

.fc-button-group .fc-button-primary:hover,
.fc-button-group .fc-button-primary:active {
  @apply bg-primary text-white;
}

.fc-button-group > .fc-button:first-of-type {
  @apply rounded-l-3xl rounded-r-none;
}

.fc-button-group .fc-button:last-of-type {
  @apply rounded-r-3xl rounded-l-none;
}

.fc-button-group .fc-button-primary.fc-button-active {
  @apply bg-primary text-white !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: rgb(80 0 120 / 50%) 0px 0px 0px 0.2rem;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  @apply bg-primary text-white;
}

.fc-col-header-cell.fc-day a.fc-col-header-cell-cushion {
  border-radius: 100%;
  padding: 8px;
  font-weight: 300;
  word-spacing: 100vw;
  text-transform: uppercase;
  width: min-content;
  padding-left: 12px;
  padding-right: 12px;
}

.fc-col-header-cell.fc-day.fc-day-today a.fc-col-header-cell-cushion {
  background-color: #500078;
  color: white;
}

.fc .fc-scrollgrid {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.fc-theme-standard th.fc-timegrid-axis {
  border: 0px;
}

.fc-col-header-cell.fc-day {
  border: 0px;
}

.mini-calendar .fc-toolbar-title {
  font-size: 14px;
}

.mini-calendar .fc .fc-prev-button,
.mini-calendar .fc .fc-next-button {
  @apply flex items-center justify-center border-transparent bg-transparent p-0 !important;
}

.mini-calendar .fc .fc-prev-button:hover,
.mini-calendar .fc .fc-next-button:hover {
  @apply bg-light-blue !important;
}

.mini-calendar .fc .fc-prev-button .fc-icon,
.mini-calendar .fc .fc-next-button .fc-icon {
  font-size: 1.2em;
}

.mini-calendar {
  width: 300px;
  margin: 0 auto;
  font-size: 10px;
}
.mini-calendar .fc-toolbar {
  font-size: 0.9em;
}
.mini-calendar .fc-toolbar h2 {
  font-size: 12px;
  white-space: normal !important;
}
/* click +2 more for popup */
.mini-calendar .fc-more-cell a {
  display: block;
  width: 85%;
  margin: 1px auto 0 auto;
  border-radius: 3px;
  background: grey;
  color: transparent;
  overflow: hidden;
  height: 4px;
}
.mini-calendar .fc-more-popover {
  width: 100px;
}
.mini-calendar .fc-view-month .fc-event,
.mini-calendar .fc-view-agendaWeek .fc-event,
.fc-content {
  font-size: 0;
  overflow: hidden;
  height: 2px;
}
.mini-calendar .fc-view-agendaWeek .fc-event-vert {
  font-size: 0;
  overflow: hidden;
  width: 2px !important;
}
.mini-calendar .fc-agenda-axis {
  width: 20px !important;
  font-size: 0.7em;
}

.mini-calendar .fc-button-content {
  padding: 0;
}

.mini-calendar .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mini-calendar .fc-daygrid-day-events {
  display: none;
}

.mini-calendar .fc-day-today a.fc-daygrid-day-number {
  background-color: #500078;
  color: white;
  border-radius: 100%;
  padding: 3px 6px;
}
.mini-calendar a.fc-daygrid-day-number {
  cursor: pointer;
}
.mini-calendar a.fc-daygrid-day-number:hover {
  background-color: #500078;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
}
.mini-calendar .fc td,
.mini-calendar .fc th {
  border-style: none !important;
}

@layer base {
  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
    stroke-width: inherit;
  }
}

@tailwind utilities;
